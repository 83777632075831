import "@fontsource-variable/open-sans";
import "@fontsource-variable/josefin-sans";

import styled, { css } from "styled-components";
import spacing from "styles/spacing";
import screenSize from "styles/mediaQueries";

export const heroFont = "3.75rem";
export const mHeroFont = "3.125rem";
export const xxlFont = "2.375rem";
export const xlFont = "1.75rem";
export const lFont = "1.5rem";
export const mFont = "1.125rem";
export const sFont = "0.875rem";
export const xsFont = "0.75rem";

interface TextProps {
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
  textAlignCenter?: boolean;
}

export const fontFamilyBrand =
  "'Open Sans Variable', Arial, Verdana, Sans-Serif";

export const fontFamilyRegular =
  "'Open Sans Variable', Arial, Verdana, Sans-Serif";

export const fontFamilyLabel = `"Josefin Sans Variable", ${fontFamilyRegular}`;

const defaultHStyles = css`
  font-weight: 700;
  line-height: 1.6;
  clear: both;
  color: var(--color-text);
  font-family: ${fontFamilyBrand};
  margin-bottom: ${spacing.md};
`;

export const h1Styles = css`
  font-size: ${xxlFont};
  ${defaultHStyles}

  ${screenSize.minTablet`
    font-size: ${xxlFont};
    line-height: 1.6;
  `}
`;

export const h2Styles = css`
  font-size: ${xlFont};
  ${defaultHStyles}

  ${screenSize.minTablet`
    font-size: ${xlFont};
    line-height: 1.2;
  `}
`;

export const h3Styles = css`
  font-size: ${lFont};
  ${defaultHStyles}

  ${screenSize.minTablet`
    font-size: ${lFont};
    line-height: 1.25;
  `}
`;

export const h4Styles = css`
  font-size: ${mFont};
  ${defaultHStyles}

  ${screenSize.minTablet`
    font-size: ${mFont};
    line-height: 1.25;
  `}
`;

export const h5Styles = css`
  font-size: ${sFont};
  ${defaultHStyles}

  ${screenSize.minTablet`
    font-size: ${sFont};
    line-height: 1.25;
  `}
`;

export const h6Styles = css`
  font-size: ${xsFont};
  ${defaultHStyles}

  ${screenSize.minTablet`
    font-size: ${xsFont};
    line-height: 1.25;
  `}
`;

export const pStyles = css`
  font-weight: 400;
  font-size: ${mFont};
  line-height: 32px;
  color: var(--color-text);
`;

export const TextH1 = styled.h1<TextProps>`
  ${(props) => props.noMarginTop && "margin-top: 0;"}
  ${(props) => props.noMarginBottom && "margin-bottom: 0;"}
  ${(props) => props.textAlignCenter && "text-align: center;"}
`;

export const TextH2 = styled.h2<TextProps>`
  ${(props) => props.noMarginTop && "margin-top: 0;"}
  ${(props) => props.noMarginBottom && "margin-bottom: 0;"}
  ${(props) => props.textAlignCenter && "text-align: center;"}
`;

export const TextH3 = styled.h3<TextProps>`
  ${(props) => props.noMarginTop && "margin-top: 0;"}
  ${(props) => props.noMarginBottom && "margin-bottom: 0;"}
  ${(props) => props.textAlignCenter && "text-align: center;"}
`;

export const TextH4 = styled.h4<TextProps>`
  ${(props) => props.noMarginTop && "margin-top: 0;"}
  ${(props) => props.noMarginBottom && "margin-bottom: 0;"}
  ${(props) => props.textAlignCenter && "text-align: center;"}
`;

export const TextP = styled.p<TextProps>`
  ${(props) => props.noMarginTop && "margin-top: 0;"}
  ${(props) => props.noMarginBottom && "margin-bottom: 0;"}
  ${(props) => props.textAlignCenter && "text-align: center;"}
`;

export const smallTextStyles = css`
  font-size: ${sFont};
  line-height: 1.6;
  margin: 0;

  ${screenSize.minTablet`
  font-size: 0.875rem;
  ${(props: TextProps) => props.noMarginTop && "margin-top: 0;"}
  ${(props: TextProps) => props.noMarginBottom && "margin-bottom: 0;"}
`}
`;

export const TextSmallP = styled.p<TextProps>`
  ${smallTextStyles}
`;

export const textLabelStyles = css<TextProps>`
  color: var(--color-text);
  font-size: ${sFont};
  line-height: 1.6;
  margin: 0;

  ${screenSize.minTablet`
    font-size: 0.875rem;
    ${(props: TextProps) => props.noMarginTop && "margin-top: 0;"}
    ${(props: TextProps) => props.noMarginBottom && "margin-bottom: 0;"}
  `}
`;

export const TextLabel = styled.span<TextProps>`
  ${textLabelStyles}
`;
